import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import supabase from './services/supabase'

//todo: move this shit to output
const mySubscription = supabase
    .from('testdata')
    .on('*', payload => {
        console.log('Change received!', payload)
    })
   // .subscribe()

const app = createApp(App)



app
  .use(router)
  .use(supabase)
  .mount('#app')


