<template>
  <div class="home">
    <p>asdasd</p>
    <button @click="add">Add more Prosecco</button>
    <div>
      <button @click="initEventListener">init motion event</button>
    </div>

    <p v-if="error">error {{ error }}</p>
    <pre v-if="event">{{ event }}</pre>
    <pre v-if="currentAcceleration">{{ currentAcceleration }}</pre>
    <pre v-if="currentGravity">{{ currentGravity }}</pre>

    <pre>Acceleration</pre>
    <pre>x: {{ acceleration.x }}</pre>
    <pre>y: {{ acceleration.y }}</pre>
    <pre>z: {{ acceleration.z }}</pre>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      acceleration: {
        x: 0,
        y: 0,
        z: 0,
      },
      currentMaxAcc: 0,
      error: null,
      event: null,
      accelerometer: null,
      eventIsHappening: false,
      currentAcceleration: false,
      currentGravity: false,
      userid: '238410929093048032'
    }
  },
  methods: {
    async connect() {
      //get all data
      await this.$supabase.from('testdata')
          .then((res) => {
            return res.data
          })
          .then(res => {
            //console.log(res)
          })
    },
    async add() {
      //insert row
      await this.$supabase.from('testdata').insert({testval: 'more prosecco'}).single()
          .then((res) => {
            //console.log(res)
            this.connect()
          })

    },
    handleDeviceMotionEvent(e) {
      this.event = e

      const acc = e.acceleration
      const maxAcceleration = Math.round(Math.max(acc.x, acc.y, acc.z) * 100) / 100
      const accGravity = e.accelerationIncludingGravity
      const maxAccGravity = Math.round(Math.max(accGravity.x, accGravity.y, accGravity.z) * 100) / 100

      this.currentAcceleration = 'Current acceleration: ' + maxAcceleration + 'm/s^2'
      this.currentGravity = 'Value incl. gravity: ' + maxAccGravity + 'm/s^2';
      this.currentAcc = 0
      this.updateInput(acc.x, acc.y, acc.z)

      if (maxAcceleration >= 0 && this.currentMaxAcc !== maxAcceleration) {
        this.updateInput(acc.x, acc.y, acc.z)
        this.acceleration.x = acc.x
        this.acceleration.y = acc.y
        this.acceleration.z = acc.z
        this.currentMaxAcc = maxAcceleration
      }
    },
    initEventListener() {
      if (typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceMotionEvent.requestPermission()
            .then(permissionState => {
              if (permissionState === 'granted') {
                window.addEventListener('devicemotion', this.handleDeviceMotionEvent, false);
              }
            })
            .catch(console.error);
      } else {
        window.addEventListener('devicemotion', this.handleDeviceMotionEvent, false);
      }
    },
    async updateInput(x = 0, y = 0, z = 0) {
      await this.$supabase
          .from('inputdata')
          .update({
            'acceleration_x': x,
            'acceleration_y': y,
            'acceleration_z': z,
            'user_id': this.userid,
          })
          .eq('user_id', this.userid)
          .then((res) => {
            console.log(res)
          })
    },
    async writeInput(x = 0, y = 0, z = 0) {
      await this.$supabase
          .from('inputdata')
          .insert({
            'acceleration_x': x,
            'acceleration_y': y,
            'acceleration_z': z,
            'user_id': this.userid,
          })
          .single()
          .then((res) => {
            console.log(res)
          })
    }
  },
  setup() {

  },
  mounted() {
    this.connect();
    // this.writeInput()


  }
}
</script>
